import classNames from 'classnames';

import ProductDisplayMode from 'types/ProductDisplayMode';
import ListTileIcon from 'assets/icons/list-tile.svg';
import ListIcon from 'assets/icons/list-list.svg';
import ListSmallIcon from 'assets/icons/list-small.svg';

import classes from './ListVariantButton.module.scss';

const iconMap: Record<ProductDisplayMode, JSX.Element> = {
  tile: <ListTileIcon />,
  list: <ListIcon />,
  compact: <ListSmallIcon />,
};

type Props = {
  name: ProductDisplayMode;
  disabled: boolean;
  className?: string;
  onDisplayModeChange: (name: ProductDisplayMode) => void;
};

const ListVariantButton = ({ name, className, disabled, onDisplayModeChange }: Props) => (
  <button
    type="button"
    title={name}
    disabled={disabled}
    className={classNames(classes.listVariantButton, className)}
    onClick={() => onDisplayModeChange(name)}
  >
    {iconMap[name]}
  </button>
);

export default ListVariantButton;
