import config from 'config';
import { Tag } from 'types/models/Tag';
import { VariantTag } from 'types/models/VariantTag';
import MappedTag from 'types/Tag';
import map from 'utils/map';

const { routes } = config;

const getMappedTags = (tags: VariantTag[] = []): MappedTag[] =>
  map<VariantTag, Tag>(tags, 'tag').map((tag) => ({
    ...tag,
    url: { pathname: routes.search.href, query: { tags: [tag.name] } },
  }));

export default getMappedTags;
