import { InputHTMLAttributes, LabelHTMLAttributes, ReactNode } from 'react';
import classNames from 'classnames';

import { Classes } from 'types/Classes';
import Message from 'types/Message';
import Typography from '@components/atoms/Typography';

import classes from './CheckmarkInput.module.scss';

export type Props = {
  id: string;
  checkmark: ReactNode;
  type: 'checkbox' | 'radio';
  error?: Message;
  name?: string;
  classes?: Classes<'root' | 'label' | 'checkmark'>;
  dataEid?: string;
  children?: ReactNode;
  checked?: boolean;
  value?: string | boolean;
  disabled?: boolean;
  isClickable?: boolean;
  onChange?: InputHTMLAttributes<HTMLInputElement>['onChange'];
} & Omit<LabelHTMLAttributes<HTMLLabelElement>, 'onChange'>;

const CheckmarkInput = ({
  id,
  type,
  name,
  checked,
  value,
  disabled,
  children,
  checkmark,
  onChange = () => {},
  className,
  classes: customClasses,
  isClickable = true,
  error,
  dataEid,
  ...rest
}: Props): JSX.Element => (
  <label
    {...rest}
    data-error={!!error}
    data-eid={dataEid}
    className={classNames(classes.wrapper, className, customClasses?.root, {
      [classes.disabledWrapper]: disabled || !isClickable,
    })}
    htmlFor={id}
  >
    <input
      id={id}
      name={name}
      type={type}
      className={classes.input}
      checked={checked}
      value={`${value}`}
      disabled={disabled}
      onClick={(e) => e.stopPropagation()}
      onChange={onChange}
    />
    {checkmark}
    <div className={classNames(classes.label, customClasses?.label)}>
      {children && (
        <div className={classNames(classes.labelWrapper, { [classes.disabled]: disabled })}>
          {typeof children === 'string' ? <Typography variant="body2">{children}</Typography> : children}
        </div>
      )}
      {!!error && <div className={classNames(classes.labelWrapper, classes.error)}>{error}</div>}
    </div>
  </label>
);

export default CheckmarkInput;
