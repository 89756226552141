import classNames from 'classnames';
import { UrlObject } from 'url';

import PossibleLink from '@components/atoms/PossibleLink';
import { FormattedMessage } from 'utils/intl';

import messages from './PaginationSizeSwitcher.messages';
import classes from './PaginationSizeSwitcher.module.scss';

type Props = {
  selected: number;
  numbers: number[];
} & (
  | { onChange: (limit: number) => void; getLimitChangeHref?: never }
  | { onChange?: never; getLimitChangeHref: (limit: number) => UrlObject }
);

const PaginationSizeSwitcher = ({ selected, getLimitChangeHref, onChange, numbers }: Props): JSX.Element => (
  <div className={classes.wrapper}>
    <div className={classes.numbers}>
      {numbers.map((number) => (
        <PossibleLink
          key={number}
          component="button"
          dataEid={number !== selected ? 'pagination-size-switcher' : ''}
          href={
            getLimitChangeHref
              ? { ...getLimitChangeHref(number), ...(number < selected ? { hash: '#last-product' } : {}) }
              : undefined
          }
          onClick={onChange ? () => onChange(number) : undefined}
          className={classNames(classes.number, {
            [classes.isSelected]: number === selected,
          })}
        >
          {number}
        </PossibleLink>
      ))}
    </div>
    <div className={classes.resultsPerPageInfo}>
      <FormattedMessage {...messages.resultsPerPage} />
    </div>
  </div>
);

export default PaginationSizeSwitcher;
