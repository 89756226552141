import config from 'config';
import Url from 'types/Url';
import { UrlVariant } from 'types/Variant';

const { routes } = config;

const getVariantHref = (variant: UrlVariant): Url => ({
  pathname: routes.product.id.href,
  query: {
    [routes.product.id.nameSlug]: variant.urlSlug,
    [routes.product.id.slug]: variant.id,
  },
});

export default getVariantHref;
