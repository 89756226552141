import { useRef, useState } from 'react';
import classNames from 'classnames';

import { NOSCRIPT_CLASSES } from 'core/noscript.styles';
import Url from 'types/Url';
import { SortId, sortingOptions } from 'consts/sort';
import PossibleLink from '@components/atoms/PossibleLink';
import Arrow from 'assets/icons/arrow2.svg';
import useOutsideClick from 'hooks/useOutsideClick';
import { FormattedMessage } from 'utils/intl';

import messages from './SortProducts.messages';
import classes from './SortProducts.module.scss';

type Props = {
  selectedItemId: SortId;
  onChangeActive?: (id: SortId) => void;
  getSortChangeHref?: (id: SortId) => Url;
};

const SortProducts = ({ selectedItemId, onChangeActive, getSortChangeHref }: Props): JSX.Element => {
  const [activeItem, setActiveItem] = useState(selectedItemId);
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<HTMLUListElement>(null);

  const handleSetActive = (id: SortId) => {
    setActiveItem(id);
    setIsOpen(false);

    if (typeof onChangeActive === 'function') {
      onChangeActive(id);
    }
  };
  useOutsideClick(
    ref,
    () => {
      if (isOpen) {
        setIsOpen(false);
      }
    },
    [isOpen]
  );

  const activeOption = sortingOptions.find(({ id }) => id === activeItem);
  const otherOptions = sortingOptions.filter(({ id }) => id !== activeItem);

  return (
    <div className={classes.container}>
      <button
        type="button"
        className={classNames(classes.wrapper, NOSCRIPT_CLASSES.SORT_PRODUCT, { [classes.wrapperWithShadow]: isOpen })}
      >
        <ul className={classes.list} ref={ref}>
          <li className={classes.activeItem}>
            <div className={classes.activeItemContainer} onClick={() => setIsOpen(!isOpen)}>
              <span className={classes.activeItemSort}>
                <FormattedMessage {...messages.itemSortBy} />
              </span>
              {activeOption?.label}
              <div className={classes.iconWrapper}>
                <Arrow className={classNames(classes.icon, { [classes.openedIcon]: isOpen })} />
              </div>
            </div>
          </li>
          {otherOptions.map(({ id, label }) => (
            <PossibleLink
              key={id}
              className={classNames({ [classes.link]: !isOpen, [classes.visibleLink]: isOpen })}
              href={getSortChangeHref?.(id)}
              component="span"
              onClick={() => handleSetActive(id)}
              scroll
              underline="hover"
            >
              <li className={classes.listItem}>{label}</li>
            </PossibleLink>
          ))}
        </ul>
      </button>
    </div>
  );
};

export default SortProducts;
