import ProductDisplayMode from 'types/ProductDisplayMode';

import ListVariantButton from './ListVariantButton';
import classes from './ListVariantSwitcher.module.scss';

export const displayModes: ProductDisplayMode[] = ['tile', 'list', 'compact'];

type Props = {
  displayMode: ProductDisplayMode;
  onDisplayModeChange: (name: ProductDisplayMode) => void;
};

const ListVariantSwitcher = ({ displayMode, onDisplayModeChange }: Props) => (
  <div className={classes.wrapper}>
    <ListVariantButton
      name="tile"
      disabled={['tile', 'list'].includes(displayMode)}
      className={classes.hideOnDesktop}
      onDisplayModeChange={onDisplayModeChange}
    />
    {displayModes.map((listType) => (
      <ListVariantButton
        key={listType}
        name={listType}
        className={listType !== 'compact' ? classes.hideOnMobile : undefined}
        disabled={displayMode === listType}
        onDisplayModeChange={onDisplayModeChange}
      />
    ))}
  </div>
);

export default ListVariantSwitcher;
