import { MouseEvent, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';

import config from 'config';
import SearchItem from 'types/SearchItem';
import ProductDisplayMode from 'types/ProductDisplayMode';
import SettingsIcon from 'assets/icons/settings.svg';
import { CartContext } from '@providers/CartProvider';
import { HolidaysContext } from '@providers/HolidaysProvider';
import Link from '@components/atoms/Link';
import Typography from '@components/atoms/Typography';
import RenderInView from '@templates/RenderInView';
import ProductCard from '@templates/ProductCard';
import CompactProduct from '@components/CompactProduct';
import HtmlPreview from '@components/HtmlPreview';
import { FormattedMessage, useIntl } from 'utils/intl';
import getVariantThumbnail from 'utils/getVariantThumbnail';
import getPlainText from 'utils/getPlainText';
import getMappedTags from 'utils/getMappedTags';
import getVariantHref from 'utils/getVariantHref';
import createStyleVariables from 'utils/createStyleVariables';
import { getDaysToDelivery } from 'utils/getShipsUntilDate';
import getUniqueFlatArray from 'utils/getUniqueFlatArray';
import checkIsConfigurable from 'utils/checkIsConfigurable';
import map from 'utils/map';

import classes from './SearchProductsList.module.scss';
import messages from './SearchProductsList.messages';

const { routes } = config;

const attributeRowHeight = 19;
const attributesMargin = 16;
const overscan = 2;

type Props = {
  data?: SearchItem[];
  displayMode: ProductDisplayMode;
  compactWithAttributes?: boolean;
  className?: string;
  scroll?: boolean;
  withFakeLink?: boolean;
};

const displayClasses: Record<ProductDisplayMode, string> = {
  tile: classes.tileMode,
  list: classes.listMode,
  compact: classes.compactMode,
};

const getId = (currentIndex: number, lastIndex: number) => {
  if (!currentIndex) {
    return 'first-product';
  }
  if (currentIndex === lastIndex) {
    return 'last-product';
  }
  return `product-${currentIndex}`;
};
const SearchProductsList = ({
  data = [],
  displayMode,
  className,
  scroll,
  compactWithAttributes,
  withFakeLink,
}: Props): JSX.Element => {
  const { onAddToCart } = useContext(CartContext);
  const { holidays } = useContext(HolidaysContext);
  const intl = useIntl();

  const [itemsVisibility, setItemsVisibility] = useState(data.map((_, index) => !index));

  const maxAttributesCount = useMemo(() => Math.max(0, ...map<SearchItem, number>(data, 'attributes.length')), [data]);

  const attributesNames = compactWithAttributes
    ? getUniqueFlatArray(data.flatMap(({ attributes }) => map(attributes || [], 'name')) as string[])
    : [];

  const checkVisibility = useCallback(
    (index: number): boolean => {
      const firstIndex = itemsVisibility.indexOf(true);
      const lastIndex = itemsVisibility.lastIndexOf(true);

      return index >= firstIndex - overscan && index <= lastIndex + overscan;
    },
    [itemsVisibility]
  );

  const onVisibilityChange = useCallback((index: number, inView: boolean) => {
    setItemsVisibility((currentVisibility) =>
      currentVisibility.map((item, currentIndex) => (currentIndex === index ? inView : item))
    );
  }, []);

  useEffect(() => {
    setItemsVisibility((currentVisibility) => data.map((_, index) => currentVisibility[index] || false));
  }, [data]);

  return (
    <div
      className={classNames(classes.products, displayClasses[displayMode], className)}
      style={createStyleVariables({
        additionalHeight: `${maxAttributesCount * attributeRowHeight + attributesMargin}px`,
      })}
      data-nosnippet
    >
      {compactWithAttributes && displayMode === 'compact' && (
        <div className={classes.headerWrapper}>
          <Typography variant="h4" renderAs="div" weight="700" className={classes.headerModel}>
            <FormattedMessage {...messages.model} />
          </Typography>
          <div className={classes.attributes}>
            {attributesNames.map((attributeName) => (
              <Typography
                variant="h4"
                key={attributeName}
                renderAs="div"
                weight="700"
                className={classes.attribute}
                style={createStyleVariables({
                  attributeWidth: `${100 / attributesNames.length}%`,
                })}
              >
                {attributeName}
              </Typography>
            ))}
          </div>
          <Typography variant="h4" renderAs="div" weight="700" className={classes.headerPrice}>
            <FormattedMessage {...messages.price} />
          </Typography>
        </div>
      )}
      {data.map(({ variant, attributes = [] }, index) => {
        const [currentSupplier] = variant.currentSuppliers;

        const isConfigurable = checkIsConfigurable(variant);

        const href = isConfigurable
          ? {
              pathname: routes.model.id.href,
              query: {
                [routes.model.id.nameSlug]: variant.product.urlSlug,
                [routes.model.id.slug]: variant.product.id,
              },
            }
          : getVariantHref(variant);
        const productProps = {
          productTypeId: variant.product.type.id,
          netPrice: variant.price,
          grossPrice: { amount: variant.grossPrice, currency: variant.price.currency },
          thumbnail: getVariantThumbnail(variant),
          rating: variant.rating || undefined,
          isEol: variant.isEol,
          isPrerelease: variant.shopsVariants[0].isPrerelease,
          isAvailable: !!variant.availability,
          currentSupplier,
          daysToDelivery: getDaysToDelivery(variant.shipsUntil, holidays),
          addIcon: isConfigurable ? SettingsIcon : undefined,
          buttonTitle: isConfigurable ? intl.formatMessage(messages.configure) : undefined,
          onAddToCart: isConfigurable
            ? () => {}
            : (e: MouseEvent<HTMLButtonElement>) => {
                e.preventDefault();
                onAddToCart(variant.id, 1);
              },
        };

        return (
          <RenderInView key={variant.id} triggerOnce={false} onChange={(e) => onVisibilityChange(index, e)}>
            {({ ref }) => (
              <span ref={ref} id={getId(index, data.length - 1)} className={classes.productCardWrapper}>
                {checkVisibility(index) || !index ? (
                  <div className={classNames(classes.card, { [classes.cardFade]: index >= 4 })}>
                    {displayMode === 'compact' ? (
                      <Link
                        href={href}
                        scroll={scroll}
                        component={withFakeLink ? 'span' : undefined}
                        className={classes.link}
                      >
                        <CompactProduct
                          producerPn={variant.producerPn}
                          productCode={isConfigurable ? '' : variant.productCode}
                          name={variant.name}
                          attributes={attributes}
                          attributesNames={attributesNames}
                          showAttributes={compactWithAttributes}
                          isConfigurable={isConfigurable}
                          imagePreload={!index}
                          imageLazyLoad={index >= 4}
                          {...productProps}
                        />
                      </Link>
                    ) : (
                      <ProductCard
                        id={variant.id}
                        href={href}
                        scroll={scroll}
                        withFakeLink={withFakeLink}
                        displayMode={displayMode}
                        name={variant.name}
                        attributes={attributes}
                        priceRrp={variant.priceRrp}
                        product={variant.product}
                        tags={getMappedTags(variant.variantsTags)}
                        imagePreload={!index}
                        imageLazyLoad={index >= 4}
                        withoutCompare={isConfigurable}
                        isConfigurable={isConfigurable}
                        {...productProps}
                      />
                    )}
                  </div>
                ) : (
                  <div
                    className={classNames(classes.placeholderLink, {
                      [classes.compactWithAttributes]: compactWithAttributes,
                    })}
                  >
                    <Link
                      href={href}
                      scroll={scroll}
                      component={withFakeLink ? 'span' : undefined}
                      className={classes.link}
                    >
                      <Typography
                        variant="body2"
                        renderAs="div"
                        ellipsisLines={1}
                        className={classes.placeholderText}
                        title={getPlainText(variant.name)}
                      >
                        <HtmlPreview htmlText={variant.name} />
                      </Typography>
                    </Link>
                  </div>
                )}
              </span>
            )}
          </RenderInView>
        );
      })}
    </div>
  );
};

export default SearchProductsList;
