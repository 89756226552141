import { useId } from 'react';

import Url from 'types/Url';
import Message from 'types/Message';
import Switch from '@components/atoms/Switch';
import PossibleLink from '@components/atoms/PossibleLink';
import Typography from '@components/atoms/Typography';
import { FormattedMessage } from 'utils/intl';

import messages from './ShowAvailable.messages';
import classes from './ShowAvailable.module.scss';

type Props = {
  isAvailable: boolean;
  href?: Url;
  messages?: {
    full: Message;
    compact: Message;
  };
  onClick?: () => void;
};

const ShowAvailable = ({
  isAvailable,
  href,
  messages: customMessages = {
    full: <FormattedMessage {...messages.full} />,
    compact: <FormattedMessage {...messages.compact} />,
  },
  onClick,
}: Props) => {
  const id = useId();

  return (
    <PossibleLink component="span" className={classes.link} href={href}>
      <Switch
        id={`is-available${id}`}
        checked={isAvailable}
        value={isAvailable}
        className={classes.checkbox}
        onClick={onClick}
      >
        <Typography variant="body2">
          <span className={classes.full}>{customMessages.full}</span>
          <span className={classes.compact}>{customMessages.compact}</span>
        </Typography>
      </Switch>
    </PossibleLink>
  );
};

export default ShowAvailable;
