import classNames from 'classnames';

import CheckmarkInput, { Props as CheckmarkInputProps } from '@components/atoms/CheckmarkInput';

import classes from './Switch.module.scss';

export type Props = Omit<CheckmarkInputProps, 'checkmark' | 'type'> & {
  size?: 'small' | 'large';
};

const Switch = ({ checked, disabled, isClickable, size = 'small', ...rest }: Props): JSX.Element => (
  <CheckmarkInput
    type="checkbox"
    checked={checked}
    disabled={disabled}
    isClickable={isClickable}
    {...rest}
    checkmark={
      <div
        className={classNames(classes.wrapper, {
          [classes.active]: checked,
          [classes.disabled]: disabled || !isClickable,
          [classes.sizeSmall]: size === 'small',
          [classes.sizeLarge]: size === 'large',
        })}
      >
        <div className={classes.dot} />
      </div>
    }
  />
);

export default Switch;
